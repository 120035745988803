Handlebars.registerPartial('mfa-magic-link-page', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n    <meta content=IE=edge http-equiv=X-UA-Compatible>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-styles"),depth0,{"name":"auth-header-styles","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"auth-webapp-core"},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":83}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"auth-react-core"},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":82}}})) != null ? stack1 : "")
    + "\n    <title>\n        Multi Factor Authentication | Egencia\n    </title>\n    <meta name=\"description\" content=\"\">\n    <meta name=\"author\" content=\"\">\n    <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n</head>\n<body id=\"mfa-magic-link-page\" class=\"wrap cf l-singlecol mfa-magic-link-page\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"page\" class=\"site-content\">\n    <div class=\"auth-content-wrapper\">\n        <div class=\"main-content\" id=\"main-content\">\n        </div>\n    </div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"mfaMagicLink"},"data":data,"loc":{"start":{"line":30,"column":0},"end":{"line":30,"column":79}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"mfa-magic-link"},"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":31,"column":81}}})) != null ? stack1 : "")
    + "\n\n<script type=\"text/JavaScript\">\n    EG.Mfa = EG.Mfa || {};\n    EG.Mfa.request = EG.Mfa.request || {};\n    EG.Auth.Presenters.mfaMagicLink(EG.Mfa.request);\n</script>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</body>\n</html>\n";
},"usePartial":true,"useData":true}));