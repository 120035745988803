Handlebars.registerPartial('forgot-password', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mobile_enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":31,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/vanity-redirection"),depth0,{"name":"partials/vanity-redirection","hash":{"imagePath":"../images/new-redirection-image-2.png"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                var siteLogoLink = $(\"#site-logo\").find(\"a#primary-nav-home\");\n                siteLogoLink = siteLogoLink && siteLogoLink[0];\n                if(siteLogoLink){\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"egenciaSiteLogoLink") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + "                }\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        siteLogoLink.href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"egenciaSiteLogoLink") || (depth0 != null ? lookupProperty(depth0,"egenciaSiteLogoLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"egenciaSiteLogoLink","hash":{},"data":data,"loc":{"start":{"line":39,"column":43},"end":{"line":39,"column":68}}}) : helper))) != null ? stack1 : "")
    + "\";\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                $('#forgetPasswordButton').addClass(\"reset-password-submit-button-margin-top\");\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                pageName = \"page.auth.SSOMixedForgotPassword\";\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_mobile_dual_screen_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                    pageName = \"page.auth.SSOMobileForgotPassword\";\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"statusMessage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":63,"column":27}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "                        pageName = \"page.auth.ForgetPasswordLinkSent\";\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":62,"column":31}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                            pageName = \"page.auth.AdminForgetPassword\";\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                            pageName = \"page.auth.ForgetPassword\";\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "                setPageStatus(\"Global.MFA.DQ.Forgot.username.password.link.display\");\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_mobile_dual_screen_enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":87,"column":27}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                    formAction = \"../email/password\";\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"egenciaLogin_admin_forgotpassword",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":119,"column":52},"end":{"line":119,"column":113}}}))
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"forgot_username_or_password",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":121,"column":52},"end":{"line":121,"column":107}}}))
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/mfa-changes-forgot-password-page"),(depth0 != null ? lookupProperty(depth0,"userInfoList") : depth0),{"name":"partials/mfa-changes-forgot-password-page","data":data,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_mobile_dual_screen_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":129,"column":40},"end":{"line":182,"column":47}}})) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input id=\"emailId\" name=\"emailId\" type=\"hidden\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"emailId") || (depth0 != null ? lookupProperty(depth0,"emailId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emailId","hash":{},"data":data,"loc":{"start":{"line":130,"column":100},"end":{"line":130,"column":111}}}) : helper)))
    + "\">\n                                            <input id=\"forceReset\" name=\"forceReset\" type=\"hidden\" value=true>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/forgot-password-dual-screen"),depth0,{"name":"partials/forgot-password-dual-screen","data":data,"indent":"                                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resetPasswordGenericMessage") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":134,"column":44},"end":{"line":181,"column":51}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/mfa-changes-forgot-password-page"),(depth0 != null ? lookupProperty(depth0,"userInfoList") : depth0),{"name":"partials/mfa-changes-forgot-password-page","data":data,"indent":"                                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                 <p class=\"alert-text-error\" id=\"error-message\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"accessTokenError") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":137,"column":96},"end":{"line":137,"column":177}}})) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNeedHelpLink") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":88},"end":{"line":142,"column":95}}})) != null ? stack1 : "")
    + "                                                                                        <script language=\"JavaScript\" type=\"text/JavaScript\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorType") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":96},"end":{"line":146,"column":103}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tuid") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":96},"end":{"line":149,"column":103}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"company_id") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":96},"end":{"line":153,"column":103}}})) != null ? stack1 : "")
    + "                                                                                        </script>\n\n                                                                                        <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.program(55, data, 0),"data":data,"loc":{"start":{"line":157,"column":92},"end":{"line":161,"column":99}}})) != null ? stack1 : "")
    + "                                                                                            <input id=\"emailId\" name=\"emailId\" type="
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.program(59, data, 0),"data":data,"loc":{"start":{"line":162,"column":132},"end":{"line":162,"column":184}}})) != null ? stack1 : "")
    + " value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"emailId") || (depth0 != null ? lookupProperty(depth0,"emailId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"emailId","hash":{},"data":data,"loc":{"start":{"line":162,"column":192},"end":{"line":162,"column":203}}}) : helper)))
    + "\" autofocus class=\"replay-safe\">\n                                                                                        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_recaptcha_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.program(63, data, 0),"data":data,"loc":{"start":{"line":165,"column":88},"end":{"line":173,"column":95}}})) != null ? stack1 : "")
    + "\n\n\n                                                                                        <button class=\"btn btn-action btn-med\" target=\"_parent\" type=\"submit\" id=\"forgetPasswordButton\"\n                                                                                                onclick='javascript:emailPassword();return false;'>\n                                                                                            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"forgotPassword.submit",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":179,"column":116},"end":{"line":179,"column":165}}}))
    + "</span>\n                                                                                        </button>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"accessTokenError") || (depth0 != null ? lookupProperty(depth0,"accessTokenError") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"accessTokenError","hash":{},"data":data,"loc":{"start":{"line":137,"column":121},"end":{"line":137,"column":141}}}) : helper)))
    + " ";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":137,"column":151},"end":{"line":137,"column":169}}}) : helper))) != null ? stack1 : "")
    + " ";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"needHelpFAQId") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":92},"end":{"line":141,"column":99}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                <span><a id=\"need_help\" class=\"need_help\" href=\"javascript:void(0);\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"login_need_help",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":140,"column":165},"end":{"line":140,"column":208}}}))
    + " </a></span>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                setFailedLoginAnalyticsData(uitk, getOmnitureTagFromErrorType(\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorType") || (depth0 != null ? lookupProperty(depth0,"errorType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorType","hash":{},"data":data,"loc":{"start":{"line":145,"column":159},"end":{"line":145,"column":172}}}) : helper)))
    + "\"));\n";
},"49":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                setUserId(uitk, \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tuid") || (depth0 != null ? lookupProperty(depth0,"tuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tuid","hash":{},"data":data,"loc":{"start":{"line":148,"column":113},"end":{"line":148,"column":121}}}) : helper)))
    + "\");\n";
},"51":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                setCompanyId(uitk, \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"company_id") || (depth0 != null ? lookupProperty(depth0,"company_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"company_id","hash":{},"data":data,"loc":{"start":{"line":152,"column":116},"end":{"line":152,"column":130}}}) : helper)))
    + "\");\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"resetPassword_email",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":158,"column":103},"end":{"line":158,"column":150}}}))
    + "</label>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"forgotPassword.uName",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":160,"column":103},"end":{"line":160,"column":151}}}))
    + "</label>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "\"text\"";
},"59":function(container,depth0,helpers,partials,data) {
    return "\"email\"";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/recaptcha-content"),depth0,{"name":"partials/recaptcha-content","hash":{"expired_call_back":"captchaExpiredCallback","success_call_back":"captchaSuccessCallback"},"data":data,"indent":"                                                                                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"auth_turnstile_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":168,"column":92},"end":{"line":172,"column":99}}})) != null ? stack1 : "");
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/turnstile-content"),depth0,{"name":"partials/turnstile-content","hash":{"unsupported_call_back":"turnstileNotSuporrtedCallback","error_call_back":"captchaErrorCallback","success_call_back":"captchaSuccessCallback"},"data":data,"indent":"                                                                                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-content"),depth0,{"name":"partials/captcha-content","data":data,"indent":"                                                                                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\"userName\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":193,"column":60},"end":{"line":193,"column":72}}}) : helper)))
    + "\" />\n";
},"70":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\"cbl\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cbl") || (depth0 != null ? lookupProperty(depth0,"cbl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cbl","hash":{},"data":data,"loc":{"start":{"line":197,"column":55},"end":{"line":197,"column":62}}}) : helper)))
    + "\" />\n";
},"72":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\"market\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"market") || (depth0 != null ? lookupProperty(depth0,"market") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"market","hash":{},"data":data,"loc":{"start":{"line":201,"column":58},"end":{"line":201,"column":68}}}) : helper)))
    + "\" />\n";
},"74":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='lang' id='lang' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lang") || (depth0 != null ? lookupProperty(depth0,"lang") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":205,"column":66},"end":{"line":205,"column":74}}}) : helper)))
    + "' />\n";
},"76":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='sid' id='sid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"securityToken") || (depth0 != null ? lookupProperty(depth0,"securityToken") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"securityToken","hash":{},"data":data,"loc":{"start":{"line":209,"column":64},"end":{"line":209,"column":81}}}) : helper)))
    + "' />\n";
},"78":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='isRequestFromHub' id='isRequestFromHub' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"isRequestFromHub") || (depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isRequestFromHub","hash":{},"data":data,"loc":{"start":{"line":213,"column":90},"end":{"line":213,"column":110}}}) : helper)))
    + "' />\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"auth_recaptcha_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.program(83, data, 0),"data":data,"loc":{"start":{"line":254,"column":16},"end":{"line":285,"column":23}}})) != null ? stack1 : "");
},"81":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    var isRecaptchaVisible = document.getElementById('recaptcha-enabled')?.value;\n                        if(isRecaptchaVisible && !isCaptchaVerified){\n                            $errorMessageField.text(\""
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"captcha_empty_response_error",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":257,"column":53},"end":{"line":257,"column":109}}}))
    + "\");\n                            errorMsgVisible = true;\n                            if(need_help){\n                                need_help.addClass(\"displayNone\");\n                            }\n                            return false;\n                        }\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"auth_turnstile_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.program(86, data, 0),"data":data,"loc":{"start":{"line":265,"column":20},"end":{"line":284,"column":27}}})) != null ? stack1 : "");
},"84":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        var isTurnstileVisible = document.getElementById('turnstile-enabled')?.value;\n                        if(isTurnstileVisible && !isCaptchaVerified){\n                            $errorMessageField.text(\""
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"captcha_empty_response_error",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":268,"column":53},"end":{"line":268,"column":109}}}))
    + "\");\n                            errorMsgVisible = true;\n                            if(need_help){\n                                need_help.addClass(\"displayNone\");\n                            }\n                            return false;\n                        }\n";
},"86":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        var captchaResponse = document.getElementById(\"nucaptcha-answer\");\n                        if (captchaResponse && captchaResponse.value == '') {\n                            $errorMessageField.text(\""
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"captcha_empty_response_error",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":278,"column":53},"end":{"line":278,"column":109}}}))
    + "\");\n                            $(\"#need_help\").text(\"\");\n                            captchaResponse.value = '';\n                            captchaResponse.focus();\n                            return false;\n                        }\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n        <meta content=IE=edge http-equiv=X-UA-Compatible>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header"),depth0,{"name":"auth-header","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "         <title>\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"forgotPassword.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":60}}}))
    + " | Egencia\n        </title>\n\n        <meta name=\"description\" content=\"\">\n        <meta name=\"author\" content=\"\">\n        <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n        <script type=\"text/javascript\" src=\"https://assets.egencia.com/mfa-component/"
    + alias3(((helper = (helper = lookupProperty(helpers,"mfaVersion") || (depth0 != null ? lookupProperty(depth0,"mfaVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mfaVersion","hash":{},"data":data,"loc":{"start":{"line":15,"column":85},"end":{"line":15,"column":99}}}) : helper)))
    + "/mfa-component.js\"></script>\n        <script src=\"https://challenges.cloudflare.com/turnstile/v0/api.js\" async defer></script>\n        <script src=\"https://www.google.com/recaptcha/api.js?hl="
    + alias3(((helper = (helper = lookupProperty(helpers,"lang") || (depth0 != null ? lookupProperty(depth0,"lang") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":64},"end":{"line":17,"column":72}}}) : helper)))
    + "\" async defer></script>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-scripts"),depth0,{"name":"partials/captcha-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </head>\n\n\n    <body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        var $errorMessageField=null;\n        $(document).ready(function(){\n            $(\"#language-menu\").addClass(\"displayNone\");\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"global_dual_screen") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + "            var captchaEnabled=\"\";\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"nonLoggedInHeaderEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"captchaFields") : depth0)) != null ? lookupProperty(stack1,"captchaEnabled") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + "            uitk.analytics.setAbTests(\"auth.captcha.audio_enabled\");\n            var pageName;\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"global_dual_screen") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enableMFAForResetPassword") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":68,"column":19}}})) != null ? stack1 : "")
    + "            sendPageNameToOmniture(uitk, pageName);\n            var refreshParams=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"refreshParams") || (depth0 != null ? lookupProperty(depth0,"refreshParams") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"refreshParams","hash":{},"data":data,"loc":{"start":{"line":70,"column":31},"end":{"line":70,"column":50}}}) : helper))) != null ? stack1 : "")
    + "\";\n            $(\"#site-utilities\").html(\"\");\n            if(window.history && window.history.replaceState && refreshParams)\n            {\n                window.history.replaceState(\"\",\"\",\"?\"+refreshParams);\n            }\n            $errorMessageField=$(\"#error-message\");\n            bindEvents();\n\n        });\n\n        function setFormAction() {\n            const form = document.getElementById('reset-password-form');\n            var formAction = \"../email/password/reset\";\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"messageHeading") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":12},"end":{"line":88,"column":23}}})) != null ? stack1 : "")
    + "            form.action = formAction;\n        }\n\n        // Call the function when the page loads\n        window.onload = function() {\n            setFormAction();\n        };\n\n    </script>\n    \n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n    <form id=\"reset-password-form\" method=\"post\" action =\"\">\n\n\n            <input type='hidden' name='locale' id='locale' value='"
    + alias3(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":66},"end":{"line":105,"column":76}}}) : helper)))
    + "' />\n\n\n\n            <div class=\"site-content\">\n                <div class=\"spaceDiv\"></div>\n                <div class=\"auth-content-wrapper\">\n                    <div class=\"box\">\n                        <div class=\"box-padded\">\n                            <div class=\"forgot-pass\">\n                                    <div class=\"flex-container\">\n                                        <div class=\"forgot-page-heading\">\n                                            <span id=\"main-forgot-heading\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":118,"column":48},"end":{"line":122,"column":55}}})) != null ? stack1 : "")
    + "                                            </span>\n                                            </div>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"messageHeading") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":126,"column":32},"end":{"line":184,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <input type='hidden' name='isLocalhost' id='isLocalhost' value='"
    + alias3(((helper = (helper = lookupProperty(helpers,"isLocalhost") || (depth0 != null ? lookupProperty(depth0,"isLocalhost") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"isLocalhost","hash":{},"data":data,"loc":{"start":{"line":190,"column":76},"end":{"line":190,"column":91}}}) : helper)))
    + "' />\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userName") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":12},"end":{"line":194,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cbl") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":12},"end":{"line":198,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"market") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":12},"end":{"line":202,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lang") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":12},"end":{"line":206,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"securityToken") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":3},"end":{"line":210,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRequestFromHub") : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":12},"end":{"line":214,"column":19}}})) != null ? stack1 : "")
    + "            <input type='hidden' name='hostName' id='hostName' value='' />\n\n\n        </form >\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </form>\n\n    </body>\n\n\n    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        document.addEventListener('DOMContentLoaded',  function() {\n            var host = window.location.hostname;\n            var hostName = host.substring(host.lastIndexOf(\".\", host.lastIndexOf(\".\") - 1) + 1);\n            document.getElementById('hostName').value = hostName;\n            if(hostName === 'localhost'){\n                document.getElementById('isLocalhost').value = true;\n                // const urlParams = new URLSearchParams(window.location.search);\n                // urlParams.set('isLocalhost', \"true\");\n                // window.location.search = urlParams;\n            }\n        });\n    </script>\n\n    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        //functions for forgotPassword\n        var need_help = $('#need_help');\n        var isCaptchaVerified = false;\n        function emailPassword() {\n            var emailId = document.getElementById(\"emailId\").value;\n            emailId = emailId.replace(/\\s/g, '');\n            if (emailId == '') {\n                $errorMessageField.text(\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"forgotPassword.emailAlert",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":247,"column":41},"end":{"line":247,"column":94}}}))
    + "\");\n                document.getElementById(\"emailId\").value = '';\n                document.getElementById(\"emailId\").focus();\n                return false;\n            }\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"captchaFields") : depth0)) != null ? lookupProperty(stack1,"captchaEnabled") : stack1),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":253,"column":9},"end":{"line":286,"column":16}}})) != null ? stack1 : "")
    + "\n            if (document.getElementById('nuCaptchaSessionId') && sessionStorage && sessionStorage.getItem(\"ndsid\")) {\n                // we are showing alternate captcha : we need to set the session id\n                document.getElementById('nuCaptchaSessionId').value = sessionStorage.getItem(\"ndsid\");\n            }\n\n            disableButton(\"forgetPasswordButton\");\n            sendClickAnalyticsData(this, \"a\", \"auth.Forgot.username.password.link\");\n            document.forms[0].submit();\n        }\n\n        function captchaSuccessCallback(token){\n            console.log('new callback')\n            uitk.logger._log(\"info\",\"Captcha verification passed on forgotpassword\");\n            isCaptchaVerified = true;\n        }\n\n        function captchaErrorCallback(error){\n            isCaptchaVerified = false;\n            uitk.logger._log(\"error\",`Captcha verification failed on forgotpassword with error: ${error}`);\n        }\n\n        function captchaExpiredCallback(){\n            isCaptchaVerified = false;\n            uitk.logger._log(\"error\",\"Captcha verification expired\");\n        }\n\n        function turnstileNotSuporrtedCallback(){\n            uitk.logger._log(\"error\",\"Turnstile not supported for this client\");\n        }\n        \n        function openHelpCenter() {\n            var contextualHelpPanel,id= event.target && event.target.id ,faqId = -1,\n                    helpUrl= \"/help-center/panel/anonymous\",\n                    helpLinkClass = \"contextual-help-link-auth\",\n                    anaylyticsData = \"auth.helpcenter.panel.defaultPage\";\n            if(id == \"need_help\"){\n                anaylyticsData= \"auth.login.helpcenter.needHelp\";\n                faqId = \""
    + alias3(((helper = (helper = lookupProperty(helpers,"needHelpFAQId") || (depth0 != null ? lookupProperty(depth0,"needHelpFAQId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"needHelpFAQId","hash":{},"data":data,"loc":{"start":{"line":325,"column":25},"end":{"line":325,"column":42}}}) : helper)))
    + "\";\n                if(faqId != -1){\n                    helpUrl= \"/help-center/panel/anonymous/\"+faqId+\"?override_back=true\";\n                }\n            }\n            contextualHelpPanel = createHelpCenterDrawer(helpUrl,helpLinkClass,true);\n            contextualHelpPanel.open();\n            sendClickAnalyticsData(this, \"a\", anaylyticsData);\n        }\n\n        function ssoRedirect() {\n            var vanityRedirectionUrl = \""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"vanityRedirectionUrl") || (depth0 != null ? lookupProperty(depth0,"vanityRedirectionUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"vanityRedirectionUrl","hash":{},"data":data,"loc":{"start":{"line":336,"column":40},"end":{"line":336,"column":66}}}) : helper))) != null ? stack1 : "")
    + "\";\n            if(vanityRedirectionUrl){\n                window.location.href = vanityRedirectionUrl;\n            }\n            disableButton(\"forgot-password-sso-login-btn\");\n            sendClickAnalyticsData(this, \"a\", \"auth.sso.login\");\n        }\n\n        function bindEvents(){\n            var $body = $(\"body\");\n            $body.on('click', '#need_help', openHelpCenter);\n            $body.on('click', '.contextual-help-link-auth', openHelpCenter);\n        }\n        function ssoMobileResetPassword() {\n            emailPassword();\n            disableButton(\"sso_reset_btn\");\n        }\n    </script>\n</html>";
},"usePartial":true,"useData":true}));