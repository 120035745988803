Handlebars.registerPartial('global-login-page', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":58},"end":{"line":26,"column":68}}}) : helper)))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"alertText") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"auth-alert-mixmode\" style=\"margin-bottom: 20px;\">\n                            <div class=\"alert alert-warn\" data-control=\"alert\">\n                            <i class=\"icon icon-warnalt\"></i>\n                                <div class=\"alert-message\">\n                                <h6 class=\"alert-title sub-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"alertTitle") || (depth0 != null ? lookupProperty(depth0,"alertTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alertTitle","hash":{},"data":data,"loc":{"start":{"line":41,"column":67},"end":{"line":41,"column":81}}}) : helper)))
    + "</h6>\n                                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"alertText") || (depth0 != null ? lookupProperty(depth0,"alertText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alertText","hash":{},"data":data,"loc":{"start":{"line":42,"column":35},"end":{"line":42,"column":50}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"pageReference") : depth0)) != null ? lookupProperty(stack1,"ssoUser") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":48},"end":{"line":63,"column":55}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <input type=\"hidden\" name=\"companyId\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pageReference") : depth0)) != null ? lookupProperty(stack1,"companyId") : stack1), depth0))
    + "\"/>\n                                                    <input type=\"hidden\" name=\"ssoUser\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pageReference") : depth0)) != null ? lookupProperty(stack1,"ssoUser") : stack1), depth0))
    + "\"/>\n                                                    <h3>"
    + alias2((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias4).call(alias3,"globalLogin.headerNewText",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":58,"column":56},"end":{"line":58,"column":109}}}))
    + "</h3>\n                                                    <div class=\"mix-mode-message\"><p class=\"\">"
    + ((stack1 = (lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias4).call(alias3,"globalLogin.new_sso_comment",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":59,"column":94},"end":{"line":59,"column":151}}})) != null ? stack1 : "")
    + "</p></div>\n                                                    <button class=\"btn btn-action btn-med col-medium-screen-12 col-small-screen-12\" target=\"_parent\" onclick=\"javascript:callIdpLogin('auth.global-login.sso.login');\">\n                                                        <span class=\"btn-label\">"
    + alias2((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias4).call(alias3,"globalLogin.new_sso_login",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":61,"column":80},"end":{"line":61,"column":133}}}))
    + "</span>\n                                                    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <p class=\"alert-text-error\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":82,"column":72},"end":{"line":82,"column":88}}}) : helper)))
    + "</p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type=\"hidden\" name=\"idpName\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"pageReference") : depth0)) != null ? lookupProperty(stack1,"idp") : stack1), depth0))
    + "\" />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type=\"hidden\" name=\"isMeeting\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"isMeeting") || (depth0 != null ? lookupProperty(depth0,"isMeeting") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isMeeting","hash":{},"data":data,"loc":{"start":{"line":90,"column":89},"end":{"line":90,"column":102}}}) : helper)))
    + "\" />\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type='hidden' name='returnUrl' id='returnUrl' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"returnUrl") || (depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":94,"column":104},"end":{"line":94,"column":117}}}) : helper)))
    + "' />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type='hidden' name='market' id='market' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"market") || (depth0 != null ? lookupProperty(depth0,"market") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"market","hash":{},"data":data,"loc":{"start":{"line":98,"column":98},"end":{"line":98,"column":108}}}) : helper)))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n	<head>\n        <meta content=IE=edge http-equiv=X-UA-Compatible>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-styles"),depth0,{"name":"auth-header-styles","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"\t      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		<title>\n			"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"globalLogin.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":11,"column":48}}}))
    + " | Egencia\n		</title>\n\n		<meta name=\"description\" content=\"Egencia SSO\">\n		<meta name=\"author\" content=\"#\">\n		<meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n\n	</head>\n	<body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n		<form>\n            <input type='hidden' name='pageName' id='pageName' value='global-login-page' />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locale") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":25,"column":3},"end":{"line":29,"column":10}}})) != null ? stack1 : "")
    + "\n\n            <div id=\"global-header-ghost-wrapper\"></div>\n\n			<div class=\"site-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAuthAlertBanner") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + "                <div>\n                    <h2>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"login_page_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":49,"column":69}}}))
    + "</h2>\n                                <div class=\"row\">\n                                    <div class=\"col col-6 col-medium-screen-12 col-small-screen-12\">\n                                        <div class=\"box mix-mode-box\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageReference") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":40},"end":{"line":64,"column":51}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n                                    <div class=\"col col-6 col-medium-screen-12 col-small-screen-12 \">\n                                        <div class=\"box mix-mode-box mix-mode-box-small-screen\">\n                                            <h3>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"globalLogin.egencia_access",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":70,"column":48},"end":{"line":70,"column":102}}}))
    + "</h3>\n                                            <div class=\"mix-mode-message\"><p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"globalLogin.new_egencia_comment1",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":71,"column":77},"end":{"line":71,"column":137}}}))
    + "</p></div>\n\n                                            <button class=\"btn btn-action btn-med  col-medium-screen-12 col-small-screen-12\" target=\"_parent\" onclick=\"javascript:callEgenciaLogin('auth.global-login.egencia.login');\">\n                                                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"globalLogin.new_egencia_login",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":74,"column":72},"end":{"line":74,"column":129}}}))
    + "</span>\n                                            </button>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"row\">\n                                    <div class=\"col col-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":40},"end":{"line":83,"column":47}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"pageReference") : depth0)) != null ? lookupProperty(stack1,"idp") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":40},"end":{"line":87,"column":47}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMeeting") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":40},"end":{"line":91,"column":47}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":40},"end":{"line":95,"column":47}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"market") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":40},"end":{"line":99,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n                                </div>\n\n\n                </div>\n            </div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</form>\n\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            overRideCommentTag();\n            $(document).ready(function(){\n                var loginType = \""
    + alias3(((helper = (helper = lookupProperty(helpers,"loginType") || (depth0 != null ? lookupProperty(depth0,"loginType") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"loginType","hash":{},"data":data,"loc":{"start":{"line":115,"column":33},"end":{"line":115,"column":46}}}) : helper)))
    + "\";\n                uitk.subscribe('modal.confirm', modalConfirmationListener);\n                uitk.subscribe('modal.close', modalCancelListener);\n                $(\"#language-options li\").closest(\"a\").attr(\"href\",\"javascript:void(0);\");\n                $('body').on('click',\"#language-options li\",changeLanguageAndRefreshEgenciaPageNew);\n                var refreshParams=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"refreshParams") || (depth0 != null ? lookupProperty(depth0,"refreshParams") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"refreshParams","hash":{},"data":data,"loc":{"start":{"line":120,"column":35},"end":{"line":120,"column":54}}}) : helper))) != null ? stack1 : "")
    + "\";\n                if(window.history && window.history.replaceState && refreshParams) {\n                     window.history.replaceState(\"\",\"\",\"refresh?refreshParams=\"+refreshParams);\n                }\n                function modalConfirmationListener(e,m)\n                {\n                    if (m != null && m.options != null) {\n                        if (m.options.modalId == \"mixed-mode-redirection-modal\") {\n                            confirmRedirection();\n                            clearTimeout(timeout);\n                        }\n                    }\n                }\n\n                function modalCancelListener(e,m){\n                    if (m != null && m.options != null) {\n                        if (m.options.modalId == \"mixed-mode-redirection-modal\") {\n                            clearTimeout(timeout);\n                            sendClickAnalyticsData(this, \"a\", \"auth.global-login.model.cancel\");\n                        }\n                    }\n                }\n\n                function confirmRedirection(isTimeOut) {\n                    if (loginType === \"LOGIN\") {\n                        var clickEvent = 'auth.global-login.model.confirm.egencia.login';\n                        if(isTimeOut){\n                            clickEvent = 'auth.global-login.model.timeout.egencia.login'\n                        }\n                        callEgenciaLogin(clickEvent);\n                    } else {\n                        var clickEvent = 'auth.global-login.model.confirm.sso.login';\n                        if(isTimeOut){\n                            clickEvent = 'auth.global-login.model.timeout.sso.login'\n                        }\n                        callIdpLogin(clickEvent);\n                    }\n                }\n\n                if (loginType) {\n                    var redirectionModalWaitingTime = \""
    + alias3(((helper = (helper = lookupProperty(helpers,"redirectionModalWaitingTime") || (depth0 != null ? lookupProperty(depth0,"redirectionModalWaitingTime") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionModalWaitingTime","hash":{},"data":data,"loc":{"start":{"line":160,"column":55},"end":{"line":160,"column":86}}}) : helper)))
    + "\";\n                    if (redirectionModalWaitingTime) {\n                        redirectionModalWaitingTime = parseInt(redirectionModalWaitingTime);\n                    } else {\n                        redirectionModalWaitingTime = 2000;\n                    }\n                    var data = {};\n                    data['imagePath'] = '../images/new-redirection-image-2.png';\n                    var redirectionModal = uitk.modal.create({\n                        modalId: 'mixed-mode-redirection-modal',\n                        content: Handlebars.templates[\"partials/mixed-mode-redirection-info-modal\"](data),\n                        dismiss : true,\n                        confirm : true,\n                        confirmText : \"Ok\",\n                        }, false);\n                    redirectionModal.open();\n\n                    var timeout = setTimeout(function () {\n                        confirmRedirection(true);\n                    },redirectionModalWaitingTime);\n                }\n                setPageNameAnalyticsData(uitk, \"page.auth.GlobalLogin\");\n                sendDataToOmniture(uitk);\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"header-right"),(depth0 != null ? lookupProperty(depth0,"nonLoggedInHeaderEnabled") : depth0),{"name":"header-right","hash":{"loadRefreshPage":true},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            });\n        </script>\n	</body>\n</html>\n\n";
},"usePartial":true,"useData":true}));